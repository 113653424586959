import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const CommentButton = () => (
  <a
    className="share-button"
    style={{
      lineHeight: '1.7rem',
      color: '#337ab7',
      paddingLeft: '0.15rem',
      visibility: 'hidden',
    }}
    href="###"
  >
    &nbsp;
  </a>
);

const ShareBox = ({ hasCommentBox }) => (
  <div className="m-share-box">
    <a
      href="###"
      className="share-button"
      style={{
        visibility: 'hidden',
      }}
    >
      &nbsp;
    </a>

    {/* 視覺置中 => 稍微往上偏移 */}
    {hasCommentBox && <CommentButton />}

    <a
      className="share-button"
      href="#header"
      style={{
        lineHeight: '1.7rem',
        paddingLeft: '0.1rem',
      }}
    >
      <i className="iconfont icontop"> </i>
    </a>
  </div>
);

ShareBox.propTypes = {
  hasCommentBox: PropTypes.bool,
};

ShareBox.defaultProps = {
  hasCommentBox: true,
};

export default ShareBox;
